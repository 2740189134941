/* ImageTooltip.css */
.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-content {
    position: absolute;
    z-index: 6;
    top:-100px;
    left:100px;
  }
  